import React from "react";
import { render } from "react-dom"; // or "react-snapshot" for poor mans SSR
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore, { MemberRefresh } from "./store";
import App from "./App";

const initialState = window.initialReduxState || {};
initialState.routing = {
  serverUrl:
    process.env.REACT_APP_SRV ||
    window.location.protocol + "//" + window.location.host,
  cdnUrl:
    process.env.REACT_APP_CDN ||
    window.location.protocol + "//" + window.location.host
};

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history, initialState);

MemberRefresh(store);

render(
  <Provider store={store}>
    <Router history={history} basename={baseUrl}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
