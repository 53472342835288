import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MemberActions } from "../store";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  //FormText,
  Label,
  Input
} from "reactstrap";

function Profile(props) {
  //const [password, setPassword] = useState("");
  //const [name, setName ] = useState(makeName(props.name, props));
  const [email, setEmail] = useState(makeEmail(props.email, props));

  return (
    <>
      <section className="user-profile section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-0 col-lg-8 offset-lg-2">
              <div className="widget user-dashboard-profile">
                <h5 className="text-center">{props.username}</h5>
                <p>{props.email}</p>
                <p className="text-center">Trading As</p>
                <h5>{props.tradingName}</h5>
              </div>

              {/*
              <div className="widget personal-info">
                <h3 className="widget-header user">
                  Edit Personal Information
                </h3>
                <Form>
                  <FormGroup className="form-group">
                    <FormText>
                      Current communication name is <em>{props.name}</em>
                    </FormText>
                    <Label for="comunity-name">Communication Name</Label>
                    <Input
                      type="text"
                      id="comunity-name"
                      placeholder="my communication name"
                      className="form-control"
                      value={name.value}
                      valid={name.value !== "" && name.error === ""}
                      invalid={name.value === "" || name.error !== ""}
                      onChange={e => setName(makeName(e.target.value, props))}
                      autoComplete="off"
                    />
                    <FormFeedback>{name.error}</FormFeedback>
                    <FormFeedback valid>
                      Your communication name is good.
                    </FormFeedback>
                  </FormGroup>
                </Form>
              </div>

              <div className="widget change-password">
                <h3 className="widget-header user">Update Profile</h3>
                <Form>
                  <FormGroup className="form-group">
                    <Label for="current-password">
                      Enter current Password to confirm changes
                    </Label>
                    <Input
                      type="password"
                      value={password}
                      className="form-control"
                      onChange={e => setPassword(e.target.value)}
                      disabled={isPasswordDisabled()}
                    />
                  </FormGroup>
                  <Alert color="warning" isOpen={!!props.profileMessage}>
                    {props.profileMessage}
                  </Alert>
                  <Button
                    className="btn btn-transparent"
                    disabled={!password || isPasswordDisabled()}
                    onClick={() => {
                      props.changeProfile(name.value, password);
                    }}
                  >
                    Save Profile Changes
                  </Button>
                </Form>
              </div>
              */}

              <div className="widget change-email mb-0">
                <h3 className="widget-header user">Change Email Address</h3>
                <Form>
                  <FormGroup className="form-group">
                    <Label for="email">Email Address to change to</Label>
                    <Input
                      id="email"
                      type="email"
                      value={email.value}
                      valid={email.enabled && email.error === ""}
                      invalid={email.error !== ""}
                      className="form-control"
                      onChange={e => setEmail(makeEmail(e.target.value, props))}
                      autoComplete="off"
                    />
                    <FormFeedback>{email.error}</FormFeedback>
                    <FormFeedback valid>
                      Your new email address is good.
                    </FormFeedback>
                  </FormGroup>
                  <Alert color="warning" isOpen={!!props.emailMessage}>
                    {props.emailMessage}
                  </Alert>
                  {email.enabled && !props.emailMessage && (
                    <Button
                      className="btn-transparent btn-block"
                      disabled={!email || !email.enabled || email.disabled}
                      onClick={() => props.emailReset(email.value)}
                    >
                      <i className="fa fa-envelope"></i> Request Change of Email
                      Address to {email.value}
                    </Button>
                  )}
                </Form>
              </div>

              <div className="widget mb-0">
                <Form>
                  <h3 className="widget-header user">
                    Request Password Change
                  </h3>
                  <Alert color="warning" isOpen={!!props.passwordMessage}>
                    {props.passwordMessage}
                  </Alert>
                  {!props.passwordMessage && (
                    <Button
                      className="btn btn-transparent btn-block"
                      onClick={() => props.passwordReset()}
                    >
                      <i className="fa fa-envelope"></i> Request Change of
                      Password Email
                    </Button>
                  )}
                </Form>
              </div>

              <div className="widget mb-0">
                <h3 className="widget-header user">Other Changes</h3>
                <a href="contact-us/">
                  <i className="fa fa-power-off"></i> Contact Us for this
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  /*
  function isPasswordDisabled() {
    return !name.enabled || name.disabled;
  }
  */

  function makeEmail(email, props) {
    email = email.trim();
    const emailRex = /^\S+@\S+\.\S+$/;
    let error;
    if (email.length < 1) {
      error = "Email is required";
    } else if (emailRex.test(email)) {
      error = "";
    } else {
      error = "Email format is bad";
    }
    return {
      value: email,
      error: error,
      disabled: error !== "",
      enabled: email !== props.email && email !== ""
    };
  }

  /*
  function makeName(name, props) {
    name = name.trim();
    const emailRex = /@/;
    let error;
    if (name.length < 1) {
      error = "Name is required";
    } else if (emailRex.test(name)) {
      error = "Name cannot contain @";
    } else {
      error = "";
    }
    return {
      value: name,
      error: error,
      disabled: error !== "",
      enabled: name !== props.name
    };
  }
  */
}

export default connect(
  state => {
    return {
      tradingName: state.Member.tokenMember.name,
      name: state.Member.userName,
      email: state.Member.email,
      profileMessage: state.Member.profileMessage,
      emailMessage: state.Member.emailMessage,
      passwordMessage: state.Member.passwordMessage
    };
  },
  dispatch => bindActionCreators(MemberActions, dispatch)
)(Profile);
