import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MemberActions } from "../store";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
} from "reactstrap";
import logo from "../Partials/ew-logo-2020-white.svg";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      validate: {
        email: "",
        password: "",
        disableSubmit: true,
      },
    };
  }

  validateEmail = (v) => {
    const emailRex = /^.+@[^.].*\.[a-z]{2,}$/;
    if (v < 1) {
      return "An email is required";
    } else if (emailRex.test(v)) {
      return "";
    } else {
      return "Email address is format is incorrect";
    }
  };

  validatePassword = (v) => {
    const passwordRex = /([0-9])/;
    if (v < 1) {
      return "A password is required";
    } else if (v < 5) {
      return "Password is too short";
    } else if (v > 25) {
      return "Password is too long";
    } else if (passwordRex.test(v)) {
      return "";
    } else {
      return "Password has no numbers";
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value =
      target.type === "checkbox" ? target.checked : target.value.trim();
    const { name } = target;

    const validate = this.state.validate;
    switch (name) {
      case "email":
        validate.email = this.validateEmail(value);
        break;
      case "password":
        validate.password = this.validatePassword(value);
        break;
      default:
        break;
    }

    const newState = {
      ...this.state,
      validate: validate,
      [name]: value,
    };
    newState.validate.disableSubmit =
      newState.email.length === 0 ||
      newState.validate.email.length !== 0 ||
      newState.password.length === 0 ||
      newState.validate.password.length !== 0;
    this.setState(newState);
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.login(this.state.email, this.state.password);
  };

  render() {
    return (
      <>
        <nav className="navbar navigation">
          <div className="navbar-brand">
            <img src={logo} alt="Edinburgh Whisky Cask Trading" />
          </div>
        </nav>
        <section className="login py-5 border-top-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-8 align-item-center">
                <div className="border">
                  <h3 className="p-4">Login Now</h3>
                  <Form onSubmit={this.submitForm}>
                    <fieldset className="px-4 pt-4">
                      <FormGroup>
                        <Alert
                          color="info"
                          isOpen={this.state.validate.disableSubmit}
                        >
                          All fields are required
                        </Alert>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="myemail@email.com"
                          className="form-control"
                          value={this.state.email}
                          valid={
                            this.state.email !== "" &&
                            this.state.validate.email === ""
                          }
                          invalid={
                            this.state.email === "" ||
                            this.state.validate.email !== ""
                          }
                          onChange={this.handleChange}
                          autoComplete="on"
                        />
                        <FormFeedback>{this.state.validate.email}</FormFeedback>
                        <FormFeedback valid>
                          Your email is formatted correctly.
                        </FormFeedback>
                        <FormText>
                          Your username is your registered email.
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="********"
                          className="border p-3 w-100 my-2"
                          value={this.state.password}
                          valid={
                            this.state.password !== "" &&
                            this.state.validate.password === ""
                          }
                          invalid={
                            this.state.password === "" ||
                            this.state.validate.password !== ""
                          }
                          onChange={this.handleChange}
                          autoComplete="current-password"
                        />
                        <FormFeedback>
                          {this.state.validate.password}
                        </FormFeedback>
                        <FormText>
                          Your password is 5 or more characters and includes a
                          number.
                        </FormText>
                      </FormGroup>
                      {/*
                      <div className="loggedin-forgot">
                        <input type="checkbox" id="keep-me-logged-in" />
                        <label
                          htmlFor="keep-me-logged-in"
                          className="pt-3 pb-2"
                        >
                          Keep me logged in
                        </label>
                      </div>
                      */}
                      <FormGroup>
                        <>
                          {this.props.isLoading && (
                            <Alert color="success" isOpen={true}>
                              Loading
                            </Alert>
                          )}
                          {this.props.isLoading || (
                            <>
                              <Alert
                                color="warning"
                                isOpen={this.props.message > ""}
                              >
                                {this.props.message}
                              </Alert>
                              <Button
                                disabled={this.state.validate.disableSubmit}
                                className="btn-block font-weight-bold"
                              >
                                Login
                              </Button>
                            </>
                          )}
                        </>
                      </FormGroup>
                    </fieldset>
                  </Form>
                  <div className="px-4 pb-4">
                    <a className="mt-3 d-block" href="/app/password/">
                      Forget Password?
                    </a>
                    <a className="mt-3 d-inline-block" href="/app/register/">
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect(
  (state) => state.Member,
  (dispatch) => bindActionCreators(MemberActions, dispatch)
)(Login);
