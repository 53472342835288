import React from "react";
import { Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MemberActions } from "./store";

import Dashboard from "./Pages/Dashboard";
import Casks from "./Pages/Casks";
import Profile from "./Pages/Profile";
import Login from "./Pages/Login";

import TopNav from "./Partials/TopNav";

function App(props) {
  if (props.tokenMember) {
    return (
      <>
        <TopNav />
        <Route exact path="/" component={Dashboard} />
        <Route path="/casks/" component={Casks} />
        <Route path="/profile/" component={Profile} />
        <Route path="/:mode/:caskId?" component={Dashboard} />
      </>
    );
    /*
  } else if (props.token && props.members && props.members.length > 1) {
    // TODO: Component for user to choose member to act for
    return <Login />;
    */
  } else {
    return <Login />;
  }
}

export default connect(
  state => {
    return { tokenMember: state.Member.tokenMember, token: state.Member.token };
  },
  dispatch => bindActionCreators(MemberActions, dispatch)
)(App);
