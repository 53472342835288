import { routerReducer, routerMiddleware } from "react-router-redux";
import {
  configureStore,
  getDefaultMiddleware,
  createSlice,
} from "@reduxjs/toolkit";

function ProcessTokenMember(tokenMember) {
  tokenMember = tokenMember || {};
  tokenMember.allCasks = tokenMember.allCasks || [];
  tokenMember.pastTrades = tokenMember.pastTrades || [];
  tokenMember.dials = tokenMember.dials || {};

  tokenMember.graphSettings = {
    evaporationPerYear: tokenMember.dials.evaporation,
    upliftPerYears: [
      tokenMember.dials.uplift1,
      tokenMember.dials.uplift2,
      tokenMember.dials.uplift3,
    ].filter(Boolean),
  };

  tokenMember.available = tokenMember.allCasks.filter(
    (e) => (e.underOffer || e.forSale) && !e.beingBought
  );
  tokenMember.live = tokenMember.allCasks.filter(
    (e) => e.beingBought || e.beingSold
  );

  tokenMember.liveBuyCount = tokenMember.live.reduce(
    (acc, e) => acc + (e.beingBought ? 1 : 0),
    0
  );
  tokenMember.liveBuyPrice = Math.round(
    tokenMember.live.reduce(
      (acc, e) => acc + (e.beingBought ? e.marketPrice || 0 : 0),
      0
    )
  );

  tokenMember.casks = tokenMember.allCasks.filter(
    (e) => !(e.underOffer || e.forSale || e.beingBought)
  );
  tokenMember.totalBook = Math.round(
    tokenMember.casks.reduce(
      (acc, e) => acc + (e.beingSold || e.beingBought ? 0 : e.bookPrice || 0),
      0
    )
  );
  tokenMember.totalMarket = Math.round(
    tokenMember.casks.reduce(
      (acc, e) => acc + (e.beingSold || e.beingBought ? 0 : e.marketPrice || 0),
      0
    )
  );
  tokenMember.trades = tokenMember.pastTrades || [];
  return tokenMember;
}

function clearMemberState(state) {
  sessionStorage.removeItem("t");
  state.token = null;
  state.email = null;
  state.userName = null;
  state.tokenMember = false;
  state.isLoading = false;
  state.message = null;
  state.errors = null;
  state.action = null;
}

function loadedMemberState(state, action) {
  state.errors = action.payload.errors;
  state.message = action.payload.message;
  state.isLoading = false;
}

const Member = createSlice({
  name: "Member",
  initialState: {
    token: sessionStorage.getItem("t"),
    action: null,
    errors: null,
    message: null,
    isLoading: false,
    email: null,
    userName: null,
    tokenMember: false,
  },
  reducers: {
    clear(state, action) {
      clearMemberState(state);
    },
    request(state, action) {
      state.isLoading = true;
    },
    receive(state, action) {
      if (action.payload.token) {
        sessionStorage.setItem("t", action.payload.token);
        state.token = action.payload.token;
        state.tokenMember = ProcessTokenMember(action.payload.tokenMember);
        state.email = action.payload.email;
        state.userName = action.payload.userName;
      } else {
        clearMemberState(state);
      }
      loadedMemberState(state, action);
    },
    sold(state, action) {
      const caskId = action.payload.caskId;
      const cask = state.tokenMember.allCasks.find((c) => c.id === caskId);
      cask.beingSold = true;
      state.tokenMember = ProcessTokenMember(state.tokenMember);
      loadedMemberState(state, action);
    },
    bought(state, action) {
      const caskId = action.payload.caskId;
      const cask = state.tokenMember.allCasks.find((c) => c.id === caskId);
      cask.beingBought = true;
      state.tokenMember = ProcessTokenMember(state.tokenMember);
      loadedMemberState(state, action);
    },
    callback(state, action) {
      // It is not persisted at the server that a callback email has been
      // sent for this cask for this member by the server.
    },
    message(state, action) {
      switch (action.payload.action) {
        case "Profile":
          state.profileMessage = action.payload.message;
          break;
        case "EmailResetT":
          state.emailMessage = action.payload.message;
          break;
        case "PasswordResetT":
          state.passwordMessage = action.payload.message;
          break;
        default:
          clearMemberState(state);
          break;
      }
      loadedMemberState(state, action);
    },
  },
});

export const MemberActions = {
  logout: () => async (dispatch, getState) => {
    dispatch({
      type: Member.actions.clear.type,
      payload: {},
    });
  },
  login: (email, password) => async (dispatch, getState) => {
    const memberState = getState().Member;
    if (memberState.isLoading) return;

    let data = new URLSearchParams();
    data.append("email", email.trim());
    data.append("password", password.trim());

    const api = "/api/Authentication/Token";
    await ApiFetch(
      Member.actions,
      Member.actions.receive,
      dispatch,
      getState,
      api,
      data
    );
  },

  sellCask: (id) => async (dispatch, getState) => {
    await CasksFetch(id, Member.actions.sold, "/sell?", dispatch, getState);
  },
  buyCask: (id) => async (dispatch, getState) => {
    await CasksFetch(id, Member.actions.bought, "/buy?", dispatch, getState);
  },
  callbackForCask: (id, reason) => async (dispatch, getState) => {
    await CasksFetch(id, Member.actions.callback, "/callback/"+reason+"?", dispatch, getState);
  },

  
  buyingNoteRedirect: (id) => async (dispatch, getState) => {
    GotoUrl("Casks/" + id + "/note/buying?", getState);
  },
  buyingNote: (id) => async (dispatch, getState) => {
    OpenUrl("Casks/" + id + "/note/buying?", getState);
  },
  sellingNote: (id) => async (dispatch, getState) => {
    OpenUrl("Casks/" + id + "/note/selling?", getState);
  },
  certificate: (id) => async (dispatch, getState) => {
    OpenUrl("Casks/" + id + "/certificate?", getState);
  },

  changeProfile: (username, currentPassword) => async (dispatch, getState) => {
    const memberState = getState().Member;
    if (!memberState.token || memberState.isLoading) return;

    const qs = new URLSearchParams();
    qs.append("t", memberState.token.trim());
    const api = "/api/Authentication/Profile?" + qs;

    const data = new URLSearchParams();
    data.append("username", username.trim());
    data.append("currentPassword", currentPassword.trim());

    await ApiFetch(
      Member.actions,
      Member.actions.receive,
      dispatch,
      getState,
      api,
      data
    );
  },
  passwordReset: () => async (dispatch, getState) => {
    ChangeRequestFetch("PasswordResetT?", dispatch, getState, null);
  },
  emailReset: (newEmail) => async (dispatch, getState) => {
    const data = new URLSearchParams();
    data.append("email", newEmail.trim());

    ChangeRequestFetch("EmailResetT?", dispatch, getState, data);
  },
};

function CalcUrl(path, getState) {
  const memberState = getState().Member;
  if (!memberState.token) return;

  const qs = new URLSearchParams();
  qs.append("t", memberState.token.trim());

  const api = "/api/" + path + qs;
  const routing = getState().routing;
  return routing.serverUrl + api;
}

function GotoUrl(path, getState) {
  const url = CalcUrl(path, getState);
  window.location.replace(url);
}

function OpenUrl(path, getState) {
  const url = CalcUrl(path, getState);
  window.open(url, "_blank");
}

async function ChangeRequestFetch(op, dispatch, getState, data) {
  const memberState = getState().Member;
  if (!memberState.token || memberState.isLoading) return;

  const qs = new URLSearchParams();
  qs.append("t", memberState.token.trim());

  const api = "/api/Authentication/" + op + qs;
  await ApiFetch(
    Member.actions,
    Member.actions.message,
    dispatch,
    getState,
    api,
    data
  );
}

async function CasksFetch(id, okAction, op, dispatch, getState) {
  const memberState = getState().Member;
  if (!memberState.token) return;

  const qs = new URLSearchParams();
  qs.append("t", memberState.token.trim());

  const api = "/api/Casks/" + id + op + qs;
  await ApiFetch(Member.actions, okAction, dispatch, getState, api, null);
}

async function ApiFetch(actions, okAction, dispatch, getState, api, data) {
  dispatch({
    type: actions.request.type,
    payload: {},
  });
  const routing = getState().routing;
  const url = routing.serverUrl + api;
  let actiontype = actions.message.type;
  try {
    const response = await fetch(url, {
      method: data !== undefined ? "post" : "get",
      body: data,
    });
    data = await response.json();
    if (response.ok) {
      actiontype = okAction.type;
    } else {
      if (!data.message) {
        data.message =
          data.errors && data.errors.text
            ? data.errors.text[0]
            : response.statusText;
      }
    }
  } catch {
    data = {
      message: "Technical fault. Please try again later.",
    };
  }
  dispatch({
    type: actiontype,
    payload: data,
  });
}

export async function MemberRefresh(store) {
  const memberState = store.getState().Member;
  if (!memberState.token) return;

  const data = new URLSearchParams();
  data.append("t", memberState.token.trim());
  const api = "/api/Authentication/MemberToken?" + data;
  await ApiFetch(
    Member.actions,
    Member.actions.receive,
    store.dispatch,
    store.getState,
    api
  );
}

export default function (history, initialState) {
  return configureStore({
    reducer: {
      Member: Member.reducer,
      routing: routerReducer,
    },
    middleware: [...getDefaultMiddleware(), routerMiddleware(history)],
    preloadedState: initialState,
  });
}
