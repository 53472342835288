import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MemberActions } from "../store";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Graphs from "../Partials/Graphs";

function ProductDetails(props) {
  return (
    <>
    <div className="col-12 my-auto mr-3 text-break">
      <h1 className="mt-0 mb-0 pt-0 pb-0">{props.item.make}</h1>
    </div>
    <div className="col-auto my-auto mr-1">
      <h2 className="mt-0 mb-0 pt-0 pb-0">{props.item.region}</h2>
    </div>
    </>
  );
}

function ProductCategory(props) {
  const ProfitLoss = (e) => {
    return e.marketPrice < e.bookPrice ? (
      <>-&pound;{(e.bookPrice - e.marketPrice).toFixed()}</>
    ) : (
      <>&pound;{(e.marketPrice - e.bookPrice).toFixed()}</>
    );
  };

  return (
    <div className="col-12 col-lg-7">
      <div className="row my-auto">
        <div className="col-12 col-md-auto text-center">
          <h3>Cask Type</h3>
          <h4>{props.item.details}</h4>
        </div>
        {/* 
        <div className="col-12 col-md-auto text-center">
          <h3>Cask Size</h3>
          <h4>{props.item.bottles} Bottles</h4>
        </div>
        */}
        <div className="col-12 col-md-auto text-center">
          {props.item.beingSold
            ? !!props.item.settledOn || (
                <>
                  <h3>Profit/Loss</h3>
                  <h5>{ProfitLoss(props.item)}</h5>
                </>
              )
            : !props.item.bookPrice || (
                <>
                  <h3>Book Value</h3>
                  <h4>&pound; {props.item.bookPrice}</h4>
                </>
          )}
        </div>
        <div className="col-12 col-md-auto text-center">
          {props.item.beingSold && props.item.settledOn ? (
            <>
              <h3>Sale Price</h3>
              <h4>&pound; {props.item.bookPrice}</h4>
            </>
          ) : (
            !props.item.marketPrice || (
              <>
                <h3>Market Price</h3>
                <h4>&pound; {props.item.marketPrice}</h4>
              </>
            )
          )}
        </div>
      </div>
      <div className="row">
        <h6 className="col text-break">
          Cask Ref {props.item.id} {" // "} Fill Date:{" "}
          <time dateTime={props.item.filledOn}>
            {moment(props.item.filledOn).format("MMM Do YYYY")}
          </time>
          {!!props.item.regaugedOn && (
            <>
              {" // "} Re guaged on:{" "}
              <time dateTime={props.item.regaugedOn}>
                {moment(props.item.regaugedOn).format("MMM Do YYYY")}
              </time>
            </>
          )}
        </h6>
      </div>
    </div>
  );
}

function Dashboard(props) {
  const caskIdParam = parseInt(props.match.params?.caskId || "0");
  const mode = parseInt(props.match.params?.mode || "1");
  const [caskId, setCaskId] = useState(0);
  const [sellingCaskId, setSellingCaskId] = useState(0);
  const [graphCask, setGraphCask] = useState(null);

  if (caskIdParam > 0 && mode === 2) {
    props.buyingNoteRedirect(caskIdParam);
  }

  const closeModals = () => {
    setGraphCask(null);
    setCaskId(0);
    setSellingCaskId(0);
  };
  const openGraphs = (e, cask) => {
    e.preventDefault();
    setGraphCask(cask);
  };
  const openConfirm = (e, caskId) => {
    e.preventDefault();
    setCaskId(caskId);
  };
  const buyingNote = (e, caskId) => {
    e.preventDefault();
    props.buyingNote(caskId);
  };
  const sellingNote = (e, caskId) => {
    e.preventDefault();
    setSellingCaskId(caskId);
  };
  const certificate = (e, caskId) => {
    e.preventDefault();
    props.certificate(caskId);
  };
  const sellCaskClick = (e, caskId) => {
    e.preventDefault();
    props.sellCask(caskId);
    setCaskId(0);
  };
  const callbackForCask = (id) => {
    props.callbackForCask(id, "saledocumentationrequested");
    setSellingCaskId(0);
  };

  return (
    <>
      <Modal isOpen={graphCask != null} toggle={closeModals} backdrop={true}>
        <ModalHeader toggle={closeModals}>Cask Graphs</ModalHeader>
        <ModalBody>
          <Graphs
            price={graphCask?.marketPrice}
            settings={props.graphSettings}
          />
          <Graphs
            tla={graphCask?.totalLitresAlcohol}
            settings={props.graphSettings}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={caskId > 0} toggle={closeModals}>
        <ModalHeader>Confirm Sale of Cask {caskId}</ModalHeader>
        <ModalBody>
          <iframe
            title="Confirm Cask Sell"
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              width: "100%",
              height: "100%",
            }}
            src={"" + props.cdnUrl + "/app/confirmsell/iframe.html"}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(event) => sellCaskClick(event, caskId)}
          >
            Confirm Sell
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModals()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={sellingCaskId > 0} toggle={closeModals}>
        <ModalHeader>Sale Documentation of Cask {sellingCaskId}</ModalHeader>
        <ModalBody>
          <iframe
            title="Sale Documentation of Cask"
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              width: "100%",
              height: "100%",
            }}
            src={"" + props.cdnUrl + "/app/saledocumentation/iframe.html"}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => callbackForCask(sellingCaskId)}
          >
            Request Callback
          </Button>
          <Button color="secondary" onClick={() => closeModals()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <section className="navbar container">
        <div className="row w-100">
          <div className="col">
            <h3 className="text-center">Your Current Portfolio</h3>
          </div>
        </div>
        <div className="row w-100">
          <div className="col-12 col-xl-4">
            <h2 className="text-left">Market Value</h2>
            <h1 className="text-left">&pound;{props.totalMarket}</h1>
          </div>
          <div className="col-12 col-xl-4">
            <h2 className="text-center">Buy Price</h2>
            <h1 className="text-center">&nbsp;&pound;{props.totalBook}</h1>
          </div>
          <div className="col-12 col-xl-4">
            <h2 className="text-right">Profit</h2>
            <h1 className="text-right">
              {props.totalMarket <= props.totalBook ? (
                <>&pound;0</>
              ) : (
                <>&pound;{(props.totalMarket - props.totalBook).toFixed()}</>
              )}
            </h1>
          </div>
        </div>
      </section>

      <section className="dashboard container">
        {mode === 1 &&
          props.casks.map((e) => (
            <div key={e.id}>
              <div className="row justify-content-between product">
                <ProductDetails item={e} />
                <ProductCategory item={e} book={true} />
                <div className="col-12 col-xl-2 my-auto mr-3 text-center">
                  {e.beingBought && <h1>Buying</h1>}
                  {e.beingSold && <h1>Selling</h1>}
                  {e.beingBought || e.beingSold || (
                    <>
                      {caskId === 0 && (
                        <button
                          className="btn"
                          onClick={(event) => openConfirm(event, e.id)}
                        >
                          Sell Cask
                        </button>
                      )}
                      <button
                        className="btn"
                        onClick={(event) => certificate(event, e.id)}
                      >
                        Certificate
                      </button>
                      <button
                        className="btn"
                        onClick={(event) => openGraphs(event, e)}
                      >
                        Graphs
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        {mode === 2 &&
          props.live.map((e) => (
            <div key={e.id} className="row justify-content-between product">
              <ProductDetails item={e} />
              <ProductCategory item={e} />
              <div className="col-12 col-xl-2 my-auto mr-3 text-center">
                {e.beingSold && (
                <button
                    className="btn"
                    onClick={(event) => sellingNote(event, e.id)}
                  >
                    Sale Documentation
                  </button>
                )}
                {e.beingBought && (
                  <button
                    className="btn"
                    onClick={(event) => buyingNote(event, e.id)}
                  >
                    Purchase Documentation
                  </button>
                )}
              </div>
            </div>
          ))}

        {mode === 3 &&
          props.trades.map((e) => (
            <div
              key={e.settledOn ? "S" + e.tradeId : "L" + e.id}
              className="row justify-content-between product"
            >
              <ProductDetails item={e} />
              <ProductCategory item={e} book={false} />
              <div className="col-12 col-xl-2 my-auto mr-3 text-center">
                <div className="justify-content-center">
                  {e.beingSold && <h4>Sold</h4>}
                  {e.beingBought && <h4>Bought</h4>}
                </div>
                <div className="justify-content-center">
                  {e.settledOn && (
                    <time>{moment(e.settledOn).format("MMM Do YYYY")}</time>
                  )}
                  {!!e.settledOn || <strong>Still Live</strong>}
                </div>
              </div>
            </div>
          ))}
      </section>
    </>
  );
}

export default connect(
  (state) => {
    return {
      graphSettings: state.Member.tokenMember.graphSettings || {},
      totalMarket: state.Member.tokenMember.totalMarket || 0,
      totalBook: state.Member.tokenMember.totalBook || 0,
      casks: state.Member.tokenMember.casks || [],
      live: state.Member.tokenMember.live || [],
      trades: state.Member.tokenMember.trades || [],
      cdnUrl: state.routing.cdnUrl,
    };
  },
  (dispatch) => bindActionCreators(MemberActions, dispatch)
)(Dashboard);
