import React from "react";
import { Line } from "react-chartjs-2";

const yAxis1 = {
  ticks: {
    callback: function(value, index, values) {
      return "£" + value;
    },
    beginAtZero: true,
    maxTicksLimit: 5
  },
  type: "linear",
  scaleLabel: {
    display: true,
    labelString: "Estimated Value"
  },
  position: "left",
  id: "y-axis-1"
};

const yAxis2 = {
  ticks: {
    beginAtZero: true,
    maxTicksLimit: 3
  },
  type: "linear",
  scaleLabel: {
    display: true,
    labelString: "Estimated Litres of Alcohol"
  },
  position: "right",
  id: "y-axis-2",
  gridLines: {
    drawOnChartArea: false // only want the grid lines for one axis to show up
  }
};

export default function Graphs(props) {
  if (!props.tla && !props.price) return null;

  const YearsHeld = [0, 3, 5, 10, 15, 20];
  const UpliftColors = ["green", "yellow", "blue"];

  let upliftPerYears = props.settings?.upliftPerYears || [];
  if (upliftPerYears.length < 1) upliftPerYears = [10.0, 12.5, 15.0];
  const evaporationPerYear = props.settings?.evaporationPerYear || 2.0;

  const lineoptions = {
    scales: {
      xAxes: [{
        scaleLabel: {
        display: true,
        labelString: "Years"
        }
      }],
      yAxes: props.tla ? [yAxis2] : [yAxis1]
    }
  };

  const linedata = {
    labels: YearsHeld
  };
  if (props.tla) {
    linedata.datasets = [
      {
        label: "Estimated Litres of Alcohol",
        fill: false,
        hidden: false,
        borderColor: "red",
        data: YearsHeld.map(yearsHeld =>
          (
            props.tla * Math.pow(1 - evaporationPerYear / 100.0, yearsHeld)
          ).toFixed(0)
        ),
        yAxesID: "y-axis-2"
      }
    ];
  } else {
    linedata.datasets = upliftPerYears
      .slice(0, UpliftColors.length)
      .filter(Boolean)
      .map((uplift, idx) => {
        return {
          label: uplift + "% Uplift",
          fill: false,
          hidden: false,
          borderColor: UpliftColors[idx],
          data: YearsHeld.map(yearsHeld =>
            (props.price * Math.pow(1 + uplift / 100.0, yearsHeld)).toFixed(0)
          ),
          yAxesID: "y-axis-1"
        };
      });
  }

  return <Line data={linedata} options={lineoptions} />;
}
