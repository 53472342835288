import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MemberActions } from "../store";
import { NavLink } from "react-router-dom";
import logo from "./ew-logo-2020-white.svg";

function TopNav(props) {
  const logoutClick = (e) => {
    e.preventDefault();
    props.logout();
  };
  return (
    <nav className="navbar navbar-expand-lg navigation">
      <div className="navbar-brand">
        <img src={logo} alt="Edinburgh Whisky Cask Trading" />
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fa fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <NavLink className="nav-link" to="/casks/">
              Buy Casks
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/1/">
              Casks{" "}
              <span>{props.casks.length}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/2/">
              Live{" "}Trades{" "}
              <span>{props.live.length}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/3/">
              Settled{" "}Trades{" "}
              <span>{props.trades.length}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/profile/">
              <i className="fa fa-cog"></i> Profile
            </NavLink>
          </li>
          <li className="nav-item">
            <a
                role="button"
                aria-pressed={false}
                href="."
                onClick={(e) => logoutClick(e)}
            >
              <i className="fa fa-sign-out"></i> Sign out
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default connect(
  (state) => {
    return {
      casks: state.Member.tokenMember.casks || [],
      live: state.Member.tokenMember.live || [],
      trades: state.Member.tokenMember.trades || [],
    };
  },
  (dispatch) => bindActionCreators(MemberActions, dispatch)
)(TopNav);

