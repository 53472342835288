import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MemberActions } from "../store";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import Graphs from "../Partials/Graphs";
import { ReactComponent as DownwardArrow } from "./DownwardArrow.svg";

const checkboxTemplate = `
<li>
    <label>
        <input type="checkbox" value="<%= value %>" />
        <span><%= name %></span>
    </label>
</li>
`;

function renderCheckboxes(casks, field, templateFn, selectedValue) {
  const $ = window.jQuery;
  const names = [];
  $.each(casks, function (i, o) {
    const v = o[field];
    if ($.inArray(v, names) < 0) names.push(v);
  });

  const container = $("#" + field + "_criteria");
  container.empty();
  $.each(names, function (i, c) {
    container.append(
      templateFn({
        name: c,
        value: c,
      })
    );
  });

  if (selectedValue) {
    $("#" + field + "_criteria :checkbox[value='" + selectedValue + "']").prop(
      "checked",
      true
    );
  } else {
    $("#" + field + "_criteria :checkbox").prop("checked", false);
  }

  $("#setall_" + field).on("click", function () {
    $("#" + field + "_criteria :checkbox").prop("checked", true);
  });
  $("#clearall_" + field).on("click", function () {
    $("#" + field + "_criteria :checkbox").prop("checked", false);
  });
}

function initSlider(field, min, low, high, max, units, sym, step) {
  const $ = window.jQuery;
  min = min - (min % step) - step;
  if (min < 1) min = 0;
  max = max - (max % step) + step;
  low = low - (low % step) - step;
  if (low < min) low = min;
  high = high - (high % step) + step;
  if (high > max) high = max;

  $("#" + field + "_range_label").html(
    "" + sym + low + " to " + sym + high + " " + units
  );
  $("#" + field + "_filter")
    .val(low + "-" + high)
    .trigger("change");
  $("#" + field + "_slider")
    .slider({
      min: min,
      max: max,
      values: [low, high],
      step: step,
      range: true,
    })
    .on("change", function (changeEvt) {
      var low = changeEvt.value.newValue[0],
        high = changeEvt.value.newValue[1];
      $("#" + field + "_range_label").html(
        "" + sym + low + " to " + sym + high + " " + units
      );
      $("#" + field + "_filter")
        .val("" + low + "-" + high)
        .trigger("change");
    });
}

const itemTemplate = `
<div class="row justify-content-between product">
  <div class="col-12 my-auto mr-3 text-break">
    <h1 class="mt-0 mb-0 pt-0 pb-0"><%= make %></h1>
  </div>
  <div class="col-auto my-auto mr-3">
      <h2 class="mt-0 mb-0 pt-0 pb-0"><%= region %></h2>
  </div>
  <div class="col-12 col-xl-5 my-auto mx-3">
    <div class="row my-auto">
      <div class="col text-center">
        <h3><%= details %></h3>
      </div>
    </div>
    <div class="row my-auto">
      <div class="col text-center">
        <h4>Listing ID <%= id %> // Age <%= age %></h4>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-2 my-auto mx-3 text-center">
    <div class="row my-auto">
      <div class="col text-center">
        <h1>&pound; <%= marketPrice %></h1>
      </div>
    </div>
    <div class="row my-auto">
      <div class="col">
      <button class="btn" onclick="FJS.itemClicked(<%= _fid %>)">Full Details</button>
      </div>
    </div>
  </div>
</div>
`;

function applyFiltering(immutableCasks, paramsString, onItemClicked) {
  if (immutableCasks.length < 1) return;

  const $ = window.jQuery;
  const casks = immutableCasks.map((c) => {
    return { ...c };
  }).sort((a,b) => a.make.localeCompare(b.make));

  $("#total_items").text(casks.length);

  const afterFilter = function (result) {
    $("#found_items").text(result.length);
  };
  const templateFn = window.FilterJS.templateBuilder(checkboxTemplate);

  let regionValue, makeValue, catValue;
  if (paramsString) {
    const searchParams = new URLSearchParams(paramsString);
    regionValue = searchParams.get("region");
    makeValue = searchParams.get("distillery");
    catValue = searchParams.get("cat");
  }

  renderCheckboxes(casks, "region", templateFn, regionValue);
  renderCheckboxes(casks, "make", templateFn, makeValue);

  const FJS = window.FilterJS(casks, "#items", {
    templateString: itemTemplate,
    callbacks: {
      afterFilter: afterFilter,
    },
    pagination: {
      container: "#pagination",
      perPage: {
        values: [10, 20, 30],
        container: "#per_page",
      },
      visiblePages: 10,
    },
  });

  let inOrder = FJS.Model.order({
    age: "asc",
  }).all;
  const minAge = inOrder[0].age;
  const maxAge = inOrder[inOrder.length - 1].age;

  let lowAge = minAge,
    highAge = maxAge;
  switch (catValue) {
    case "Emerging":
      highAge = 10;
      break;
    case "Intermediate":
      lowAge = 11;
      highAge = 20;
      break;
    case "Mature":
      lowAge = 21;
      break;
    default:
      catValue = null;
      break;
  }
  if (lowAge > highAge) lowAge = highAge;
  if (catValue) {
    $("#results_cat").text('"' + catValue + '"');
    $("#clear_cat").show();
  }

  initSlider("age", minAge, lowAge, highAge, maxAge, "years", "", 1);

  inOrder = FJS.Model.order({
    marketPrice: "asc",
  }).all;
  const minPrice = inOrder[0].marketPrice;
  const maxPrice = inOrder[inOrder.length - 1].marketPrice;

  initSlider("price", minPrice, minPrice, maxPrice, maxPrice, "", "£", 100);

  FJS.itemClicked = (_fid) => {
    const item = casks[_fid - 1];
    onItemClicked(item);
  };

  window.FJS = FJS;

  window.FJS.addCriteria([
    {
      field: "marketPrice",
      ele: "#price_filter",
      type: "range",
    },
    {
      field: "age",
      ele: "#age_filter",
      type: "range",
    },
    {
      field: "region",
      ele: "#region_criteria input:checkbox",
    },
    {
      field: "make",
      ele: "#make_criteria input:checkbox",
    },
  ]);
  window.FJS.filter();

  $("body").on("touchstart.dropdown", ".dropdown", function (e) {
    e.stopPropagation();
  });

  $("select").niceSelect();
}

function removeFiltering() {
  window.FJS.removeCriteria("price");
  window.FJS.removeCriteria("age");
  window.FJS.removeCriteria("region");
  window.FJS.removeCriteria("make");
}

function CaskTable(props) {
  if (!props.cask._fid) return null;
  return (
    <table className="table table-bordered product-table ">
      <tbody>
        <tr>
          <td>Age</td>
          <td>{props.cask.age} years</td>
        </tr>
        <tr>
          <td>Age of youngest spirit</td>
          <td>{moment(props.cask.filledOn).format("MMMM Do YYYY")}</td>
        </tr>
        <tr>
          <td>Cask Details</td>
          <td>{props.cask.details}</td>
        </tr>
        <tr>
          <td>Alcohol by Volume</td>
          <td>{props.cask.alcoholByVolumePercentage}%</td>
        </tr>
        <tr>
          <td>Lying in Bond at</td>
          <td>{props.cask.lyingIn}</td>
        </tr>
        <tr>
          <td>Bulk Litres of Spirit</td>
          <td>{props.cask.bulkLitresSpirit.toFixed()} litres</td>
        </tr>
        {!!props.cask.regaugedOn && (
          <>
            <tr>
              <td>Re Guage Date</td>
              <td>
                <time>
                  {moment(props.cask.regaugedOn).format("MMMM Do YYYY")}
                </time>
              </td>
            </tr>
          </>
        )}
        <tr>
          <td>Total Litres Alcohol</td>
          <td>{props.cask.totalLitresAlcohol.toFixed()} litres</td>
        </tr>
        <tr>
          <td>
            <strong>Price</strong>
          </td>
          <td>
            <strong>&pound; {props.cask.marketPrice}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Casks(props) {
  const [cask, setCask] = useState({ _fid: 0 });
  const [confirm, setConfirm] = useState(null);

  const closeModal = () => {
    closeConfirm();
    setCask({ _fid: 0 });
  };
  const buyCask = (id) => {
    props.buyCask(id);
    setConfirm("bought");
  };
  const buyingNote = (e, caskId) => {
    e.preventDefault();
    props.buyingNote(caskId);
    closeModal();
  };
  const callbackForCask = (id) => {
    closeConfirm();
    props.callbackForCask(id, "requested");
    setCask({ _fid: 0 });
  };
  const closeConfirm = () => {
    setConfirm(null);
  };
  const openConfirm = () => {
    if (cask.underOffer) {
      props.callbackForCask(cask.id, "underoffer");
      setConfirm("underoffer");
    } else if (
      props.casksBuyingLimit &&
      props.liveBuyCount >= props.casksBuyingLimit
    ) {
      props.callbackForCask(cask.id, "countlimit");
      setConfirm("block");
    } else if (
      props.cashBuyingLimit &&
      cask.marketPrice + props.liveBuyPrice > props.cashBuyingLimit
    ) {
      props.callbackForCask(cask.id, "cashlimit");
      setConfirm("block");
    } else {
      setConfirm("confirm");
    }
  };

  useEffect(() => {
    const showModal = (cask) => {
      closeConfirm();
      setCask(cask);
    };

    applyFiltering(props.forSaleCasks, props.filteringParams, showModal);
    return () => {
      window.FJS.clear();
      removeFiltering();
    };
  }, [props.filteringParams, props.forSaleCasks]);

  return (
    <>
      <Modal
        isOpen={cask._fid > 0}
        toggle={closeModal}
        size="lg"
        centered={false}
      >
        <ModalHeader toggle={closeModal} cssModule={{ "modal-title": "w-100" }}>
          <div className="w-50 float-left text-left">{cask.make}</div>
          <div className="w-50 float-right text-right">{cask.region}</div>
        </ModalHeader>
        <ModalBody className="container-fluid">
          <Modal
            isOpen={cask._fid > 0 && confirm !== null}
            toggle={closeConfirm}
          >
            <ModalHeader>Buy of Cask {cask.id}</ModalHeader>
            <ModalBody>
              {confirm !== null && (
                <iframe
                  title="Buy of Cask"
                  style={{
                    overflowX: "hidden",
                    overflowY: "visible",
                    width: "100%",
                    height: "100%",
                  }}
                  src={
                    "" + props.cdnUrl + "/app/" + confirm + "buy/iframe.html"
                  }
                />
              )}
            </ModalBody>
            <ModalFooter>
              {confirm === "bought" ? (
                <Button color="primary" onClick={(event) => buyingNote(event, cask.id)}>
                  Purchase Documentation
                </Button>
              ) : confirm === "confirm" ? (
                <Button color="primary" onClick={() => buyCask(cask.id)}>
                  Confirm Buy
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => callbackForCask(cask.id)}
                >
                  Request Callback
                </Button>
              )}
              {confirm === "bought" ? null : (
              <Button color="secondary" onClick={() => closeConfirm()}>
                Cancel
              </Button>
              )}
            </ModalFooter>
          </Modal>
          {confirm === null ? (
            <ModalFooter className="border-top-0 pt-0 border-bottom">
              <Button color="primary" onClick={openConfirm}>
                Buy
              </Button>
              <Button color="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          ) : null}
          {cask.forestryPage && (
            <Row>
              <Col lg="7">
                <iframe
                  title="Cask Overview"
                  style={{
                    overflowX: "hidden",
                    overflowY: "visible",
                    width: "100%",
                    height: "100%",
                    minHeight: "400px",
                  }}
                  src={
                    "" +
                    props.cdnUrl +
                    "/" +
                    cask.forestryPage +
                    "/iframe.html"
                  }
                />
              </Col>
              <Col lg="5">
                <CaskTable cask={cask} />
              </Col>
            </Row>
          )}
          {!!cask.forestryPage || <CaskTable cask={cask} />}
          <Graphs price={cask.marketPrice} settings={props.graphSettings} />
        </ModalBody>
        {confirm === null ? (
          <ModalFooter>
            <Button color="primary" onClick={openConfirm}>
              Buy
            </Button>
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
      <section className="navbar container">
        <div className="row w-100">
          <div className="col">
            <h2 className="text-right">CASK MARKETPLACE</h2>
            <h3 className="text-right">Set filters to see matching casks</h3>
          </div>
        </div>
      </section>
      {props.forSaleCasks.length < 1 ? (
        <section className="section-sm">
          <div className="text-center">
            <h2>There are no casks available to buy</h2>
          </div>
        </section>
      ) : (
        <>
          <section className="container navigation">
            <div className="row row-eq-height">
              <div className="col">
                <div className="widget dropdown w-100">
                  <h4 className="widget-header text-center">Distillery</h4>
                  <div className="dropdown-content">
                    <button
                      type="button"
                      className="widget-button btn w-50 d-inline-block mr-0"
                      id="setall_make"
                      aria-label="set all distilleries"
                    >
                      Set&nbsp;All
                    </button>
                    <button
                      type="button"
                      className="widget-button btn w-50 d-inline-block ml-0"
                      id="clearall_make"
                      aria-label="clear all distilleries"
                    >
                      Clear&nbsp;All
                    </button>
                    <ul className="category-list" id="make_criteria"></ul>
                  </div>
                  <DownwardArrow />
                </div>
              </div>
              <div className="col">
                <div className="widget dropdown w-100">
                  <h4 className="widget-header text-center">Region</h4>
                  <div className="dropdown-content">
                    <button
                      type="button"
                      className="widget-button btn w-50 d-inline-block mr-0"
                      id="setall_region"
                      aria-label="set all regions"
                    >
                      Set&nbsp;All
                    </button>
                    <button
                      type="button"
                      className="widget-button btn w-50 d-inline-block mr-0"
                      id="clearall_region"
                      aria-label="clear all regions"
                    >
                      Clear&nbsp;All
                    </button>
                    <ul className="category-list" id="region_criteria"></ul>
                  </div>
                  <DownwardArrow />
                </div>
              </div>
              <div className="d-xl-none w-100"></div>
              <div className="col">
                <div className="widget range-widget  w-100">
                  <h4 className="widget-header text-center">Price Range</h4>
                  <div className="text-center">
                    <b id="price_range_label" className="slider-label"></b>
                  </div>
                  <div className="text-center">
                    <div id="price_slider" className="slider"></div>
                  </div>
                  <input type="hidden" id="price_filter" value="0-100000" />
                </div>
              </div>
              <div className="col">
                <div className="widget range-widget  w-100">
                  <h4 className="widget-header text-center">Age Range</h4>
                  <div className="text-center">
                    <b id="age_range_label" className="slider-label"></b>
                  </div>
                  <div className="text-center">
                    <div id="age_slider" className="slider"></div>
                  </div>
                  <input type="hidden" id="age_filter" value="0-999" />
                </div>
              </div>
            </div>
          </section>

          <section className="dashboard container">
            <div className="row">
              <div className="col">
                <h2 className="text-right success font-weight-bold">
                  <span id="found_items"></span> casks found of{" "}
                  <span id="total_items"></span> total
                </h2>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col" id="items"></div>
            </div>
            <div className="row mt-3 justify-content-between">
              <div className="col view">
                <strong>Casks in a page:</strong> <span id="per_page"></span>
              </div>
              <div className="col-auto">
                <div
                  id="pagination"
                  className="pagination justify-content-center"
                ></div>
              </div>
              <div className="col view"></div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default connect(
  (state) => {
    return {
      liveBuyCount: state.Member.tokenMember.liveBuyCount || 0,
      liveBuyPrice: state.Member.tokenMember.liveBuyPrice || 0,
      casksBuyingLimit: state.Member.tokenMember.casksBuyingLimit || 0,
      cashBuyingLimit: state.Member.tokenMember.cashBuyingLimit || 0,
      graphSettings: state.Member.tokenMember.graphSettings || {},
      forSaleCasks: state.Member.tokenMember.available || [],
      cdnUrl: state.routing.cdnUrl,
    };
  },
  (dispatch) => bindActionCreators(MemberActions, dispatch)
)(Casks);
